import React, { useState, useEffect } from "react";
import { Link } from 'gatsby';
import { navigate } from "gatsby"
import "../scss/global.scss";
import SEO from "../components/seo";
import bgCurve from "../images/home/pg-404-logo.svg"
import bgLogo from "../images/logo-golden.svg"

const NotFoundPage = (props) => {
  const [showtemplate, setShowtemplate] = useState(false);
 
  useEffect(() => {
    const pathname = props?.location?.pathname;
    console.log('pathname', pathname)
    if (pathname.indexOf("/commercial-property-for-rent/") >= 0) {
      navigate("/commercial-properties/for-rent/in-doha-qatar/")
    } else if (pathname.indexOf("/commercial-property-for-sale/") >= 0) {
      navigate("/commercial-properties/for-sale/in-doha-qatar/")
    } else if (pathname.indexOf("/off-plan-project-for-sale/") >= 0) {
      navigate("/off-plan-projects/for-sale/in-doha-qatar/")
    } else if (pathname.indexOf("/off-plan-property-for-sale/") >= 0) {
      navigate("/off-plan-properties/for-sale/in-doha-qatar/")
    } else if (pathname.indexOf("/properties-for-sale/") >= 0) {
      navigate("/properties/for-sale/in-doha-qatar/")
    } else if (pathname.indexOf("/properties-for-rent/") >= 0) {
      navigate("/properties/for-rent/in-doha-qatar/")
    } else {
      if(!showtemplate){
        setShowtemplate(true)
      }
      
    }
  })


  return (
    <>
      {showtemplate &&
        <div className="content null">
          <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span>
          <div className="not-found-landing error-full-bg">
            <SEO title="404: Not found" />
            <div className="not-found-page">
              <div className="not-found-image"><img src={bgLogo} /></div>
              <h1 className="not-found-title">We are sorry, the page you were looking for cannot be found.</h1>
              <p>Please search again using different terms, or contact our office for more help.</p>

              <div class="not-found-block">
                <Link to="/" class="btn btn-primary"><span>Return Home</span></Link>
                <Link to="/contact-us" class="btn btn-number btn-outline"><span>Contact Us</span></Link>
              </div>
            </div>
          </div>
        </div>
      }

    </>
  )
}



export default NotFoundPage
